export const routes = {
  404: '/404',
  500: '/500',
  auth: {
    signIn: (props?: { returnPath?: string; errorMessage?: string }) => {
      let query = '';

      if (props?.returnPath) query += `?returnUrl=${encodeURIComponent(props?.returnPath)}`;
      if (props?.errorMessage)
        query += query
          ? `&error=${encodeURIComponent(props?.errorMessage)}`
          : `?error=${encodeURIComponent(props?.errorMessage)}`;
      return `/auth/signin${query}`;
    },
    auth0: {
      login: (returnUrl?: string) => (returnUrl ? `/api/auth/login?returnUrl=${returnUrl}` : '/api/auth/login'),
      logout: '/api/auth/logout',
    },
  },
  dashboard: '/',
  clients: {
    root: '/clients',
    create: '/clients/create',
    groups: (id?: string) => ({
      root: `/clients/groups${id ? `?portfolioGroups_f.id=${id}` : ''}`,
      create: '/clients/groups/create',
      group: (id?: string) => ({
        edit: `/clients/groups/${id}/edit`,
      }),
    }),
    corporate: {
      create: `/clients/corporate/create`,
    },
    trust: {
      create: `/clients/trust/create`,
    },
    client: (id: string) => ({
      root: `/clients/${id}/overview`,
      accounts: `/clients/${id}/accounts`,
      details: `/clients/${id}/details`,
      transfers: {
        root: `/clients/${id}/transfers`,
        transfer: (transferId: string) => ({
          root: `/clients/${id}/transfers/${transferId}`,
          edit: (stepId?: string) => `/clients/${id}/transfers/${transferId}/edit${stepId ? `#${stepId}` : ''}`,
        }),
      },
      notifications: `/clients/${id}/notifications`,
      capitalGains: `/clients/${id}/capital-gains`,
      createChild: `/clients/${id}/createChild`,
      createAccount: `/clients/${id}/accounts/create`,
      edit: (stepId?: string) => `/clients/${id}/edit${stepId ? `#${stepId}` : ''}`,
      internalMovement: `/clients/${id}/internal-movement`,
    }),
  },
  accounts: {
    root: '/accounts',
    account: (accountId: string) => ({
      root: `/accounts/${accountId}/investments`,
      report: `/accounts/${accountId}/report`,
      transactions: `/accounts/${accountId}/transactions`,
      details: `/accounts/${accountId}/details`,
      edit: (stepId?: string) => `/accounts/${accountId}/edit${stepId ? `#${stepId}` : ''}`,
      allTransactions: (assetId: string) => `/accounts/${accountId}/assets/${assetId}/all-transactions`,
      actions: {
        withdraw: `/accounts/${accountId}/withdraw`,
        payIn: `/accounts/${accountId}/pay-in`,
        sell: `/accounts/${accountId}/sell`,
        crystallise: `/accounts/${accountId}/crystallise`,
        invest: `/accounts/${accountId}/invest`,
        adHocFee: `/accounts/${accountId}/ad-hoc-fee`,
        transfer: `/accounts/${accountId}/transfer`,
      },
    }),
  },
  models: (modelType: 'advisory' | 'discretionary') => ({
    root: `/models/${modelType}`,
    create: `/models/${modelType}/create`,
    model: (modelId: string) => ({
      root: `/models/${modelType}/${modelId}`,
      edit: (stepId?: string) => `/models/${modelType}/${modelId}/edit${stepId ? `#${stepId}` : ''}`,
      clone: `/models/${modelType}/${modelId}/clone`,
      rebalance: `/models/discretionary/${modelId}/rebalance`,
    }),
  }),
  illustrations: {
    root: '/illustrations',
    create: '/illustrations/create',
  },
  transactions: {
    root: '/transactions',
    query: (params: Record<string, string>) => `/transactions?${new URLSearchParams(params).toString()}`,
  },
  instruments: {
    root: `/instruments/existing`,
    available: `/instruments/available`,
    instrument: (instrumentId: string) => ({
      root: `/instruments/${instrumentId}`,
      request: `/instruments/${instrumentId}/request`,
      edit: (stepId?: string) => `/instruments/${instrumentId}/edit${stepId ? `#${stepId}` : ''}`,
    }),
  },
  reporting: '/reporting',
  transfers: (params?: string | string[][] | Record<string, string> | URLSearchParams | undefined) =>
    `/transfers${params ? `?${new URLSearchParams(params).toString()}` : ''}`,
  users: {
    root: '/users',
    create: '/users/create',
    user: (id: string) => ({
      root: `/users/${id}`,
      edit: (stepId?: string) => `/users/${id}/edit${stepId ? `#${stepId}` : ''}`,
    }),
  },
};
